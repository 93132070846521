<template>
    <div class="headerContainer">
        <div class="headerSpacer"></div>
        <div v-if="steps && steps.length && $mq.mobile" class="stepperSpacer"></div>
        <div class="shadow fixed top-0 left-0 right-0 z-20">
            <div v-if="$mq.mobile">
                <div :class="['grid grid-cols-3 items-center h-[60px] px-2', light ? 'bg-cream' : 'bg-red text-cream']">
                    <div class="flex items-center pl-1">
                        <div class="cursor-pointer flex items-center" @click="drawer = true">
                            <icon icon="mdi:menu" class="h-3 w-auto" />
                            <span class="pl-1 hidden md:block">{{ $t("Menu") }}</span>
                        </div>
                    </div>
                    <div class="h-inherit flex justify-center items-center">
                        <slot>
                            <img class="logo cursor-pointer h-full w-[76px]" :src="light ? '/logo_stacked.svg' : '/logo_white.svg'" @click="logoRedirect" />
                        </slot>
                    </div>
                    <div class="flex justify-end items-center">
                        <div v-if="couponLabel && light" class="h5 bg-yellow px-1 py-05 rounded-full font-bold cursor-pointer text-center" @click="couponPopup = true">{{ couponLabel }}</div>
                    </div>
                </div>
                <div v-if="steps && steps.length" class="flex justify-center items-center h-[60px] bg-cream px-[40px] pb-05">
                    <Stepper :step="step" :progress="progress" :steps="steps" />
                </div>
            </div>
            <div v-else :class="['grid grid-cols-3 h-[60px] px-[120px]', light ? 'bg-cream' : 'bg-red text-cream']">
                <div class="h-inherit flex items-center">
                    <slot>
                        <img class="logo cursor-pointer h-full w-[76px]" :src="light ? '/logo_stacked.svg' : '/logo_white.svg'" @click="logoRedirect" />
                    </slot>
                </div>
                <div class="flex items-center">
                    <Stepper v-if="steps && steps.length" :step="step" :progress="progress" :steps="steps" />
                </div>
                <div class="flex items-center">
                    <div v-if="couponLabel && light" class="ml-4 h5 bg-yellow px-1 py-05 rounded-full font-bold cursor-pointer text-center" @click="couponPopup = true">{{ couponLabel }}</div>
                    <div class="ml-auto cursor-pointer flex items-center" @click="drawer = true">
                        <span class="pr-1 hidden md:block">{{ $t("Menu") }}</span>
                        <icon icon="mdi:menu" class="h-3 w-auto" />
                    </div>
                </div>
            </div>
        </div>
        <Drawer v-model="drawer" :right="!$mq.mobile">
            <div class="bg-red h-full w-full flex flex-col text-cream drawerContentWrapper">
                <div class="mx-3">
                    <div class="flex justify-between">
                        <div style="width: 24px"></div>
                        <img class="mt-3 drawerLogo cursor-pointer" src="/logo_white.svg" @click="logoRedirect" />
                        <div class="cursor-pointer" @click="drawer = false">
                            <icon icon="mdi:close" class="link text-cream mt-3" />
                        </div>
                    </div>
                </div>
                <div class="px-5 pt-5" v-if="!$route.path.includes('insurance') && authenticated">
                    <nuxt-link class="block text-cream text-center font-bold mb-3" :to="localePath('/pet-overview')">{{ $t("My pets") }}</nuxt-link>
                    <nuxt-link v-if="!$route.path.includes('questionnaires')" class="block text-cream text-center font-bold mb-3" :to="localePath('/questionnaires')">{{ $t("Add another pet") }}</nuxt-link>
                    <nuxt-link v-if="activeSubscription" class="block text-cream text-center font-bold mb-3" :to="localePath('/dashboard')">{{ $t("Back to dashboard") }}</nuxt-link>
                </div>
                <div class="mt-auto px-[40px] pb-4">
                    <div class="flex flex-col items-center gap-1 text-lg">
                        <div v-if="email" class="text-cream mb-2" style="overflow: hidden; text-overflow: ellipsis; width: 100%; text-align: center">{{ email }}</div>
                        <LanguageSelector buttonStyles="hover:bg-cream hover:text-black" />
                        <Button v-if="authenticated" wide color="cream" outlined @click="logout()">{{ $t("Log out") }}</Button>
                        <Button v-else-if="!$route.path.includes('/signin')" wide color="cream" outlined @click="login()">{{ $t("Sign in") }}</Button>
                    </div>
                </div>
            </div>
        </Drawer>
        <Popup v-model="couponPopup" small>
            <div v-if="coupon" class="text-center md:text-left md:px-3">
                <h2 class="font-bold mb-2">{{ $t("Applied discount code") }}</h2>
                <div class="mb-1 flex justify-center">
                    <div class="bg-light-grey px-2 mx:px-4 py-1 rounded-full flex justify-center items-center gap-1">
                        <div class="text-2xl">
                            <icon icon="mdi:tag" />
                        </div>
                        <div>
                            <b>{{ $t("Coupon code:") }} </b>
                            <span>{{ coupon.code }}</span>
                        </div>
                    </div>
                </div>
                <div class="mb-2 mx-3 h5">{{ $t("You can still remove or change the discount code at checkout") }}</div>
                <div v-if="coupon.customer">
                    <div class="text-center font-bold">{{ $t("Free testbag for dogs and 20% discount for cats") }}</div>
                </div>
                <div v-else-if="coupon.type === 'sequential'">
                    <div v-for="sequence of sequences" class="flex justify-center gap-05">
                        <div>{{ $t("{number} delivery:", { number: sequence.order + (sequence.order === 1 ? $t("st") : sequence.order === 2 ? $t("nd") : sequence.order === 3 ? $t("rd") : $t("th")) }) }}</div>
                        <div class="font-bold">{{ sequence.type === "percentage" ? $t("{amount}% discount", { amount: sequence.discount_amount }) : $t("{amount} discount", { amount: $helpers.format.currency(sequence.discount_amount, false, true) }) }}</div>
                    </div>
                </div>
                <div v-else>
                    <div class="flex justify-center gap-05">
                        <div>{{ $t("{number} delivery:", { number: 1 + $t("st") }) }}</div>
                        <div class="font-bold">{{ coupon.type === "percentage" ? $t("{amount}% discount", { amount: coupon.discount_amount }) : $t("{amount} discount", { amount: $helpers.format.currency(coupon.discount_amount, false, true) }) }}</div>
                    </div>
                </div>
            </div>
        </Popup>
    </div>
</template>

<script>
    import Button from "./Button.vue"
    import Stepper from "./Stepper.vue"
    import WordpressLinks from "../mixins/WordpressLinks"
    import LanguageSelector from "../features/language/LanguageSelector.vue"
    import Drawer from "./Drawer.vue"
    import Popup from "./Popup.vue"

    import ToggleAuthStateMixin from "../mixins/ToggleAuthStateMixin"

    export default {
        components: { Button, Stepper, LanguageSelector, Drawer, Popup },
        mixins: [ToggleAuthStateMixin, WordpressLinks],
        props: {
            steps: {
                type: Array,
                default: () => [],
            },
            light: Boolean,
            wordpressLogoLink: Boolean,
        },
        data: () => ({
            drawer: false,
            step: 0,
            progress: 50,
            coupon: null,
            couponPopup: false,
        }),
        created() {
            this.$nuxt.$on("step", ({ step, progress }) => {
                if (step !== undefined) this.step = step
                if (progress !== undefined) this.progress = progress
            })
        },
        mounted() {
            if (this.authenticated) {
                this.$store.dispatch("api/recurringDeliveries/get")
            }
            this.checkUrlCoupon()
        },
        computed: {
            authenticated() {
                return this.$store.getters["authentication/authenticated"]
            },
            activeSubscription() {
                let status = this.$store.state.api.recurringDeliveries?.[0]?.subscription_status
                return status && status !== "unverified"
            },
            email() {
                return this.$store.state.authentication?.currentUser?.email
            },
            couponLabel() {
                if (!this.coupon || (!this.coupon.discount_amount && !this.coupon.sequences[0]?.discount_amount)) return null
                if (this.coupon.customer) return this.$t("Friend discount")
                if (this.coupon.type === "percentage") return this.$t("{amount}% discount", { amount: this.coupon.discount_amount })
                if (this.coupon.type === "absolute") return this.$t("{amount} discount", { amount: this.$helpers.format.currency(this.coupon.discount_amount, false, true) })
                if (this.coupon.type === "sequential") {
                    if (this.coupon.sequences[0]?.type === "percentage") return this.$t("{amount}% discount", { amount: this.coupon.sequences[0].discount_amount })
                    if (this.coupon.sequences.every(x => x.type === "absolute"))
                        return this.$t("{amount} discount", {
                            amount: this.$helpers.format.currency(
                                this.coupon.sequences.reduce((total, x) => total + x.discount_amount, 0),
                                false,
                                true,
                            ),
                        })
                    if (this.coupon.sequences[0]?.type === "absolute") return this.$t("{amount} discount", { amount: this.$helpers.format.currency(this.coupon.sequences[0].discount_amount, false, true) })
                }
                return this.coupon
            },
            sequences() {
                return this.coupon.sequences.sort((a, b) => a.order - b.order)
            },
        },
        methods: {
            logoRedirect() {
                this.wordpressLogoLink ? (window.location.href = `http://${this.$localization.currentCountry.wordpress_domain_name}${this.$localization.currentLanguage.wordpress_slug}`) : this.$router.push(this.localePath("/"))
            },
            async checkUrlCoupon() {
                if (this.$store.state.api.coupon) {
                    this.coupon = this.$store.state.api.coupon
                } else if (this.$route.query.onboarding) {
                    const { valid, coupon_code } = await this.$axios.$get(`/couponcode/validate_onboarding/?signed_code=${this.$route.query.onboarding}`)
                    if (valid && coupon_code) this.coupon = coupon_code
                } else {
                    this.coupon = null
                }
            },
        },
        watch: {
            authenticated() {
                this.checkUrlCoupon()
            },
            "$store.state.api.coupon"() {
                this.checkUrlCoupon()
            },
        },
    }
</script>

<style lang="scss">
    .headerContainer {
        // Spacing
        header {
            height: unset !important;
            // box-shadow: 3px 3px 6px rgb(0 0 0 / 16%) !important;
            z-index: 201 !important;
        }
        .v-toolbar__content {
            height: unset !important;
            display: block;
            padding: 0;
        }
        .headerSpacer {
            height: 60px !important;
            @media (max-width: 960px) {
                height: 60px !important;
            }
        }
        .stepperSpacer {
            height: 60px !important;
        }
        // Main header
        .header {
            display: grid;
            grid: auto / 1fr auto 1fr;
            margin: 0;
            & > * {
                padding: var(--space-1) var(--space-3);
            }
            .icon {
                height: 32px !important;
                width: 32px !important;
                i {
                    font-size: 32px !important;
                }
            }
            .link {
                color: inherit;
            }
            .h-inherit {
                height: inherit;
            }
            @media (max-width: 960px) {
                height: 60px !important;
                & > * {
                    padding: var(--space-1);
                }
                .icon {
                    height: 32px !important;
                    width: 32px !important;
                    i {
                        font-size: 24px !important;
                    }
                }
                .logo {
                    margin-left: 0;
                }
            }
        }
        // Drawer
        .drawer {
            z-index: 203 !important;
            width: 400px !important;
            z-index: 202 !important;
            background-color: var(--color-red) !important;
            color: var(--color-cream) !important;
            @media (max-width: 960px) {
                width: 100vw !important;
                height: 110vh !important;
            }
            .drawerContentWrapper {
                min-height: 100vh !important;
                @media (max-width: 960px) {
                    min-height: 85vh !important;
                }
                .drawerLogo {
                    max-height: 80px;
                }
            }
        }
        // Stores colors
        .header {
            background-color: var(--color-red) !important;
            border-color: var(--color-red) !important;
            color: var(--color-cream) !important;
            i {
                color: var(--color-cream) !important;
            }
            &.light {
                background-color: var(--color-cream) !important;
                border-color: var(--color-cream) !important;
                color: var(--color-black) !important;
                i {
                    color: var(--color-black) !important;
                }
            }
        }
        .stepperHeader {
            background-color: var(--color-cream) !important;
        }
    }
</style>
