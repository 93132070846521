export default async function (context) {
    console.debug("Entering auth middleware")
    const { app, store, redirect, route, $helpers, $localization, $moment } = context

    await Promise.all([store.dispatch("api/countries/get"), store.dispatch("api/languages/get")])
    if ($localization.locale && $moment.locale() !== $localization.locale) $moment.locale($localization.locale)

    // ensure the user is navigated to appropriate webpages
    async function redirectToAppriopriatePage(route, user) {
        let path = `${route.path}`

        if (path !== "/" && path[path.length - 1] === "/") {
            path = path.slice(0, -1)
        }

        const pathParts = path.split("/")
        // IMPORTANT : The language might also be something like be-nl. It has to be planned here to prevent an infinite loop
        const hasLanguagePrefix = pathParts.length > 1 && /^(([a-z]){2}|([a-z]){2}-([a-z]){2}){1}$/.test(pathParts[1])

        await Promise.all([store.dispatch("api/countries/get"), store.dispatch("api/languages/get")])

        // Determine locale, in order of preference:
        // 1. Existing URL locale prefix
        // 2. Customer prefered language
        // 3. Default language of current country
        // 4. English as last fallback
        const defaultLanguage = $localization.defaultLanguage
        let locale = "en"
        if (hasLanguagePrefix) {
            locale = pathParts[1].toLowerCase()
            // Strip the locale prefix from the path we use for mathing.
            // The leading '/' is important, removing this will cause infinite loops due to the checks below no longer matching.
            path = "/" + pathParts.slice(2).join("/")
        } else if (store.state.api.customer && store.state.api.customer.prefered_language) {
            const prefered_language = store.state.api.customer.prefered_language
            locale = store.state.api.languages.find(x => x.url === prefered_language).iso_code.toLowerCase()
        } else if (defaultLanguage) {
            locale = defaultLanguage.iso_code.toLowerCase()
        } else if (app.i18n.locale) {
            locale = app.i18n.locale
        }

        // Check if translations exist for the chosen locale, otherwise fall back to English.
        if (!defaultLanguage || !app.i18n.locales.map(item => item.code).includes(defaultLanguage.iso_code.toLowerCase())) {
            locale = "en"
        }

        console.debug(`Current path: ${path}`)
        const userOnIndex = path === "/"
        const userOnSigninPages = path.includes("/signin") || path.includes("/signup") || path.includes("/forgot-password")
        const userOnEhealth = path.startsWith("/ehealth")
        const userOnDashboard = path.startsWith("/dashboard")
        const userOnQuestionnaire = path.startsWith("/questionnaires")
        const userOnCheckout = path !== "/checkout/thankyou" && (path.startsWith("/checkout") || path.startsWith("/pet-overview") || path.startsWith("change-pet-profile"))

        // User not authenticated
        if (user === null) {
            if (userOnIndex) {
                console.debug(`Redirecting to /${locale}/signup`)
                return redirect({ path: `/${locale}/signup`, query: route.query })
            }
            if (userOnQuestionnaire || userOnCheckout || userOnEhealth) {
                return redirect({ path: `/${locale}/signin`, query: { ...route.query, next: route.fullPath } })
            }
        } else {
            // User authenticated
            await store.dispatch("api/recurringDeliveries/get")

            // Handle inactive user
            if (store.state.api.recurringDeliveries?.[0]?.subscription_status !== "active") {
                await store.dispatch("api/animals/get")
                if (!user.email) {
                    if ((userOnIndex || userOnSigninPages || userOnDashboard || userOnEhealth || userOnCheckout || userOnQuestionnaire) && !path.startsWith("/questionnaires/profile")) {
                        console.debug(`Redirecting to /${locale}/questionnaires/profile`)
                        return redirect({ path: `/${locale}/questionnaires/profile`, query: route.query })
                    }
                }
                if (store.state.api.animals.length < 1) {
                    if (userOnIndex || userOnSigninPages || userOnDashboard || userOnEhealth || userOnCheckout) {
                        console.debug(`Redirecting to /${locale}/questionnaires`)
                        return redirect({ path: `/${locale}/questionnaires`, query: route.query })
                    }
                }
                if (userOnIndex || userOnSigninPages || userOnDashboard || userOnEhealth) {
                    console.debug(`Redirecting to /${locale}/pet-overview`)
                    return redirect({ path: `/${locale}/pet-overview`, query: route.query })
                }
            } else {
                // Handle active user
                if (userOnIndex || userOnSigninPages) {
                    console.debug(`Redirecting to /${locale}/dashboard`)
                    return redirect({ path: `/${locale}/dashboard`, query: route.query })
                }
            }
        }

        if (!hasLanguagePrefix && locale) {
            console.debug(`Redirecting to /${locale}${route.fullPath}`)
            return redirect(`/${locale}${route.fullPath}`)
        }

        console.debug(`Allowing through to ${path}`)

        return true
    }
    redirectToAppriopriatePage(route, store.getters["authentication/currentUser"])
}
