<template>
    <div>
        <div class="fixed top-0 left-0 right-0 z-20 shadow">
            <div class="bg-red text-cream h-[60px]">
                <div class="hidden lg:flex items-center gap-[64px] px-[120px] !py-1 h-full">
                    <nuxt-link :to="localePath('/')" class="h-full">
                        <img class="logo cursor-pointer h-full min-w-[76px]" src="/logo_white.svg" />
                    </nuxt-link>
                    <nuxt-link :to="localePath('/dashboard')" class="h-full flex items-center" :class="{ 'font-bold underline': /^.*\/dashboard\/?$/.test($route.path) }" testid="dashboard.deliveries">{{ $t("Deliveries") }}</nuxt-link>
                    <nuxt-link :to="localePath('/dashboard/mealplan')" class="h-full flex items-center" :class="{ 'font-bold underline': $route.path.includes('/dashboard/mealplan') }" testid="dashboard.mealplan">{{ $t("Feedingplan") }}</nuxt-link>
                    <nuxt-link :to="localePath('/dashboard/pets')" class="h-full flex items-center" :class="{ 'font-bold underline': $route.path.includes('/dashboard/pets') }" testid="dashboard.pets">{{ $t("My pets") }}</nuxt-link>
                    <nuxt-link :to="localePath('/dashboard/shop')" class="h-full flex items-center" :class="{ 'font-bold underline': $route.path.includes('/dashboard/shop') }" testid="dashboard.shop">{{ $t("Shop") }}</nuxt-link>
                    <div class="ml-auto h-full flex items-center gap-3">
                        <nuxt-link :to="localePath('/dashboard/share')" class="h-full flex items-center gap-1">
                            <img src="/dashboard_header/Share-white.webp" class="h-[24px] w-auto" />
                            <div :class="{ 'font-bold underline': $route.path.includes('/dashboard/share') }">{{ $t("Share Just Russel") }}</div>
                        </nuxt-link>
                        <Menu v-model="menu" class="h-full" right0>
                            <template v-slot:activator>
                                <div @click="menu = !menu" class="flex items-center cursor-pointer h-full" testid="dashboard.toggle_menu">
                                    <img src="/dashboard_header/Account-white.webp" class="h-[24px] w-auto" />
                                    <div class="ml-1">{{ firstname }}</div>
                                    <icon :icon="menu ? 'mdi:chevron-up' : 'mdi:chevron-down'" class="h-[24px] w-auto" />
                                </div>
                            </template>
                            <div class="shadow bg-cream rounded text-black">
                                <div class="rounded-t overflow-auto pt-1">
                                    <div @click="menu = false">
                                        <nuxt-link :to="localePath('/dashboard/profile')" class="flex items-center gap-3 px-3 py-1 hover:bg-meat border-l-4 border-transparent" :class="{ '!border-red text-red': $route.path.includes('/dashboard/profile') }" testid="dashboard.account_information">
                                            <img :src="$route.path.includes('/dashboard/profile') ? '/dashboard_header/Account-orange.webp' : '/dashboard_header/Account-probile.webp'" class="h-[33px] w-auto" />
                                            <div>{{ $t("Account information") }}</div>
                                        </nuxt-link>
                                        <nuxt-link :to="localePath('/dashboard/manage/deliveries')" class="flex items-center gap-3 px-3 py-1 hover:bg-meat border-l-4 border-transparent" :class="{ '!border-red text-red': $route.path.includes('/dashboard/manage/deliveries') }" testid="dashboard.subscription_settings">
                                            <img :src="$route.path.includes('/dashboard/manage/deliveries') ? '/dashboard_header/Subscription-settings-orange.webp' : '/dashboard_header/Subscription-settings.webp'" class="h-[33px] w-auto" />
                                            <div>{{ $t("Subscription settings") }}</div>
                                        </nuxt-link>
                                        <nuxt-link :to="localePath('/dashboard/history')" class="flex items-center gap-3 px-3 py-1 hover:bg-meat border-l-4 border-transparent" :class="{ '!border-red text-red': $route.path.includes('/dashboard/history') }" testid="dashboard.order_history">
                                            <img :src="$route.path.includes('/dashboard/history') ? '/dashboard_header/History-orange.webp' : '/dashboard_header/History-black.webp'" class="h-[33px] w-auto" />
                                            <div>{{ $t("Order history") }}</div>
                                        </nuxt-link>
                                        <nuxt-link :to="localePath('/dashboard/wallet')" class="flex items-center gap-3 px-3 py-1 hover:bg-meat border-l-4 border-transparent" :class="{ '!border-red text-red': $route.path.includes('/dashboard/wallet') }" testid="dashboard.payment_methods">
                                            <img :src="$route.path.includes('/dashboard/wallet') ? '/dashboard_header/Payment-orange.webp' : '/dashboard_header/Payment-black.webp'" class="h-[33px] w-auto" />
                                            <div>{{ $t("Payment methods") }}</div>
                                        </nuxt-link>
                                        <nuxt-link :to="localePath('/dashboard/amount')" class="flex items-center gap-3 px-3 py-1 hover:bg-meat border-l-4 border-transparent" :class="{ '!border-red text-red': $route.path.includes('/dashboard/amount') }" testid="dashboard.delivery_amount">
                                            <img :src="$route.path.includes('/dashboard/amount') ? '/dashboard_header/Delivery-amount-orange.webp' : '/dashboard_header/Delivery-amount-black.webp'" class="h-[33px] w-auto" />
                                            <div>{{ $t("Delivery amount") }}</div>
                                        </nuxt-link>
                                        <div class="border-b border-light-grey"></div>
                                        <nuxt-link v-if="showEhealth" :to="localePath('/ehealth')" class="flex items-center gap-3 px-3 py-1 hover:bg-meat border-l-4 border-transparent" :class="{ '!border-red text-red': $route.path.includes('/ehealth') }" testid="dashboard.health">
                                            <img :src="$route.path.includes('/ehealth') ? '/dashboard_header/Health-orange.webp' : '/dashboard_header/Health-black.webp'" class="h-[33px] w-auto" />
                                            <div>{{ $t("Health") }}</div>
                                        </nuxt-link>
                                        <a :href="$localization.currentLanguage.zendesk_link" target="_blank" class="flex items-center gap-3 px-3 py-1 hover:bg-meat border-l-4 border-transparent" testid="dashboard.help">
                                            <img src="/dashboard_header/Helpcenter.webp" class="h-[33px] w-auto" />
                                            <div>{{ $t("Help") }}</div>
                                        </a>
                                    </div>
                                </div>
                                <div class="border-b border-light-grey"></div>
                                <div class="p-3">
                                    <LanguageSelector class="mb-2" buttonStyles="hover:bg-red hover:text-white" />
                                    <Button outlined class="mb-2" @click="logout" testid="dashboard.log_out" style="width: 100%">{{ $t("Log out") }}</Button>
                                    <div class="font-bold text-center">{{ $t("Logged in with:") }}</div>
                                    <div class="flex justify-center items-center gap-1">
                                        <img src="/dashboard_header/Envelope.webp" class="h-[12px] w-auto" />
                                        <div style="overflow: hidden; text-overflow: ellipsis">{{ email }}</div>
                                    </div>
                                </div>
                            </div>
                        </Menu>
                    </div>
                </div>
                <div class="flex lg:hidden items-center h-full mx-2">
                    <div class="w-full shrink" @click="drawerLeft = !drawerLeft">
                        <icon icon="mdi:menu" class="h-[33px] w-auto" />
                    </div>
                    <nuxt-link :to="localePath('/')" class="h-full">
                        <img class="logo cursor-pointer h-full min-w-[76px]" src="/logo_white.svg" />
                    </nuxt-link>
                    <div class="w-full shrink flex justify-end items-center gap-2">
                        <nuxt-link :to="localePath('/dashboard/share')">
                            <img src="/dashboard_header/Share-white.webp" class="h-[33px] w-auto" />
                        </nuxt-link>
                        <div @click="drawerRight = !drawerRight">
                            <img src="/dashboard_header/Account-white.webp" class="h-[33px] w-auto" />
                        </div>
                    </div>
                    <Drawer v-model="drawerLeft" notFullMobile>
                        <div class="w-full h-full bg-cream text-black">
                            <div class="flex justify-end p-3">
                                <div class="border rounded-full" @click="drawerLeft = false">
                                    <icon icon="mdi:close" class="h-[24px] w-[24px]" />
                                </div>
                            </div>
                            <div @click="drawerLeft = false">
                                <nuxt-link :to="localePath('/dashboard')" class="flex items-center gap-3 px-3 py-1 hover:bg-meat border-r-4 border-transparent" :class="{ '!border-red text-red': /^.*\/dashboard\/?$/.test($route.path) }">
                                    <img :src="/^.*\/dashboard\/?$/.test($route.path) ? '/dashboard_header/Overview-orange.webp' : '/dashboard_header/Overview-black.webp'" class="h-[33px] w-auto" />
                                    <div>{{ $t("Deliveries") }}</div>
                                </nuxt-link>
                                <nuxt-link :to="localePath('/dashboard/mealplan')" class="flex items-center gap-3 px-3 py-1 hover:bg-meat border-r-4 border-transparent" :class="{ '!border-red text-red': $route.path.endsWith('/dashboard/mealplan') }">
                                    <img :src="$route.path.includes('/dashboard/mealplan') ? '/dashboard_header/Feedingplan-orange.webp' : '/dashboard_header/Feedingplan-black.webp'" class="h-[33px] w-auto" />
                                    <div>{{ $t("Feedingplan") }}</div>
                                </nuxt-link>
                                <nuxt-link :to="localePath('/dashboard/pets')" class="flex items-center gap-3 px-3 py-1 hover:bg-meat border-l-4 border-transparent" :class="{ '!border-red text-red': $route.path.endsWith('/dashboard/pets') }">
                                    <img :src="$route.path.includes('/dashboard/pets') ? '/dashboard_header/Pets-orange.webp' : '/dashboard_header/Pets-black.webp'" class="h-[33px] w-auto" />
                                    <div>{{ $t("My pets") }}</div>
                                </nuxt-link>
                                <nuxt-link :to="localePath('/dashboard/shop')" class="flex items-center gap-3 px-3 py-1 hover:bg-meat border-r-4 border-transparent" :class="{ '!border-red text-red': $route.path.endsWith('/dashboard/shop') }">
                                    <img :src="$route.path.includes('/dashboard/shop') ? '/dashboard_header/Shop-orange.webp' : '/dashboard_header/Shop-black.webp'" class="h-[33px] w-auto" />
                                    <div>{{ $t("Shop") }}</div>
                                </nuxt-link>
                                <nuxt-link v-if="showEhealth" :to="localePath('/ehealth')" class="flex items-center gap-3 px-3 py-1 hover:bg-meat border-r-4 border-transparent" :class="{ '!border-red text-red': $route.path.includes('/ehealth') }">
                                    <img :src="$route.path.includes('/ehealth') ? '/dashboard_header/Health-orange.webp' : '/dashboard_header/Health-black.webp'" class="h-[33px] w-auto" />
                                    <div>{{ $t("Health") }}</div>
                                </nuxt-link>
                                <a :href="$localization.currentLanguage.zendesk_link" target="_blank" class="flex items-center gap-3 px-3 py-1 hover:bg-meat border-r-4 border-transparent">
                                    <img src="/dashboard_header/Helpcenter.webp" class="h-[33px] w-auto" />
                                    <div>{{ $t("Help") }}</div>
                                </a>
                            </div>
                        </div>
                    </Drawer>
                    <Drawer v-model="drawerRight" right notFullMobile>
                        <div class="w-full h-full bg-cream text-black flex flex-col">
                            <div class="flex justify-between p-3">
                                <h2>{{ $t("Hi {name}", { name: firstname }) }}</h2>
                                <div>
                                    <div class="border rounded-full" @click="drawerRight = false">
                                        <icon icon="mdi:close" class="h-[24px] w-[24px]" />
                                    </div>
                                </div>
                            </div>
                            <div @click="drawerRight = false">
                                <nuxt-link :to="localePath('/dashboard/profile')" class="flex items-center gap-3 px-3 py-1 hover:bg-meat border-l-4 border-transparent" :class="{ '!border-red text-red': $route.path.endsWith('/dashboard/profile') }">
                                    <img :src="$route.path.includes('/dashboard/profile') ? '/dashboard_header/Account-orange.webp' : '/dashboard_header/Account-probile.webp'" class="h-[33px] w-auto" />
                                    <div>{{ $t("Account information") }}</div>
                                </nuxt-link>
                                <nuxt-link :to="localePath('/dashboard/manage/deliveries')" class="flex items-center gap-3 px-3 py-1 hover:bg-meat border-l-4 border-transparent" :class="{ '!border-red text-red': $route.path.endsWith('/dashboard/manage/deliveries') }">
                                    <img :src="$route.path.includes('/dashboard/manage/deliveries') ? '/dashboard_header/Subscription-settings-orange.webp' : '/dashboard_header/Subscription-settings.webp'" class="h-[33px] w-auto" />
                                    <div>{{ $t("Subscription settings") }}</div>
                                </nuxt-link>
                                <nuxt-link :to="localePath('/dashboard/history')" class="flex items-center gap-3 px-3 py-1 hover:bg-meat border-l-4 border-transparent" :class="{ '!border-red text-red': $route.path.endsWith('/dashboard/history') }">
                                    <img :src="$route.path.includes('/dashboard/history') ? '/dashboard_header/History-orange.webp' : '/dashboard_header/History-black.webp'" class="h-[33px] w-auto" />
                                    <div>{{ $t("Order history") }}</div>
                                </nuxt-link>
                                <nuxt-link :to="localePath('/dashboard/wallet')" class="flex items-center gap-3 px-3 py-1 hover:bg-meat border-l-4 border-transparent" :class="{ '!border-red text-red': $route.path.endsWith('/dashboard/wallet') }">
                                    <img :src="$route.path.includes('/dashboard/wallet') ? '/dashboard_header/Payment-orange.webp' : '/dashboard_header/Payment-black.webp'" class="h-[33px] w-auto" />
                                    <div>{{ $t("Payment methods") }}</div>
                                </nuxt-link>
                                <nuxt-link :to="localePath('/dashboard/amount')" class="flex items-center gap-3 px-3 py-1 hover:bg-meat border-l-4 border-transparent" :class="{ '!border-red text-red': $route.path.endsWith('/dashboard/amount') }">
                                    <img :src="$route.path.includes('/dashboard/amount') ? '/dashboard_header/Delivery-amount-orange.webp' : '/dashboard_header/Delivery-amount-black.webp'" class="h-[33px] w-auto" />
                                    <div>{{ $t("Delivery amount") }}</div>
                                </nuxt-link>
                            </div>
                            <div class="mt-auto flex flex-col gap-2 p-3">
                                <LanguageSelector />
                                <Button wide outlined @click="logout">{{ $t("Log out") }}</Button>
                                <div class="font-bold text-center">{{ $t("Logged in with:") }}</div>
                                <div class="flex justify-center items-center gap-1">
                                    <img src="/dashboard_header/Envelope.webp" class="h-[12px] w-auto" />
                                    <div style="overflow: hidden; text-overflow: ellipsis">{{ email }}</div>
                                </div>
                            </div>
                        </div>
                    </Drawer>
                </div>
            </div>
            <div v-if="steps && step !== 0" class="flex items-center justify-center h-[60px] bg-cream">
                <div class="w-full mx-2 md:w-1/3">
                    <Stepper :steps="steps" :step="step" :progress="progress" />
                </div>
            </div>
        </div>
        <div :class="[steps && step !== 0 ? 'mb-[129px]' : 'mb-[60px]']"></div>
    </div>
</template>

<script>
    import Menu from "~/components/library/Menu.vue"
    import Button from "~/components/library/Button.vue"
    import Drawer from "~/components/library/Drawer.vue"
    import Stepper from "~/components/library/Stepper.vue"
    import LanguageSelector from "../language/LanguageSelector.vue"

    export default {
        props: {
            steps: Array,
        },
        components: { Menu, LanguageSelector, Button, Drawer, Stepper },
        data: () => ({
            menu: false,
            drawerLeft: false,
            drawerRight: false,
            step: 0,
            progress: 50,
        }),
        mounted() {
            this.$nuxt.$on("step", ({ step, progress }) => {
                if (step !== undefined) this.step = step
                if (progress !== undefined) this.progress = progress
            })
        },
        computed: {
            firstname() {
                return this.$store.state.api.customer?.firstname
            },
            email() {
                return this.$store.state.authentication?.currentUser?.email
            },
            showEhealth() {
                return this.$store.state.api.animals.some(pet => pet.type === "dog" && pet.activated_at && !pet.deleted_at) && this.$store.getters["api/currentCountry"].short_name === "BE"
            },
        },
        methods: {
            async logout() {
                this.$firebaseAuth.signOut()
                await this.$store.dispatch("authentication/logout")
                this.$axios.setToken(null)
                this.$router.push(this.localePath("/signin"))
            },
        },
    }
</script>
