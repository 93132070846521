const CustomerVisitMixin = (mixinParams = null) => ({
    computed: {
        customer() {
            return this.$store.state.api.customer
        },
        visitedPath() {
            const fullPath = this.$route.fullPath
            const parts = fullPath.split("?")
            let pathSection = parts[0]
            const querySection = parts.length > 1 ? `?${parts[1]}` : ""

            if (!pathSection.endsWith("/")) {
                pathSection += "/"
            }

            let result = `${pathSection}${querySection}`
            if (!result.endsWith("/")) {
                result += "/"
            }

            return result
        },
        visitedPathWithoutQuery() {
            return this.$route.path
        },
        customerVisit: {
            get() {
                if (!this.$store.state.api.visits) return null
                return this.$store.state.api.visits.find(v => v.path === this.visitedPath) || null
            },
            set(value) {
                if (this.$store.state.api.visits?.some(visit => visit.path === value.path)) {
                    this.$store.commit(
                        "api/visits/set",
                        this.$store.state.api.visits.map(visit => {
                            if (visit.path === value.path) visit = value
                            return visit
                        }),
                    )
                } else {
                    this.$store.commit("api/visits/set", [...this.$store.state.api.visits, value])
                }
            },
        },
        // alreadyRegistered() {
        //   if (!this.customerVisit) { return false }
        //   return this.customerVisit.path === this.visitedPath
        // },
        visitedPathIsValid() {
            if (!mixinParams || !mixinParams["excludedRoutes"]) {
                return true
            }
            let excluded = mixinParams["excludedRoutes"].some(predicate => {
                let pathIsValid = true
                let queryIsValid = true

                if (predicate.path) {
                    pathIsValid = this.$route.path !== predicate.path
                }
                if (predicate.query) {
                    // predicate.query must be included completely in route.query to be invalid
                    queryIsValid = Object.keys(predicate.query).some(queryKey => {
                        return `${this.$route.query[queryKey]}` !== `${predicate.query[queryKey]}`
                    })
                }
                return !pathIsValid || !queryIsValid
            })

            return !excluded
        },
    },
    data() {
        return {
            env: this.$config.env,
            isMounted: true,
        }
    },
    beforeMount() {
        this.isMounted = false
    },
    mounted() {
        if (!mixinParams || mixinParams["registerOnMount"]) {
            this.registerCustomerVisit()
        }
        this.isMounted = true
    },
    methods: {
        isFirstVisit() {
            return !this.customerVisit && window.sessionStorage.getItem("visit-" + this.visitedPath) === null
        },

        async safeRegisterCustomerVisit() {
            await this.$store.dispatch("api/customer/get")
            this.registerCustomerVisit()
        },
        registerCustomerVisit() {
            if (!this.customer) return
            if (this.isFirstVisit()) {
                window.sessionStorage.setItem("visit-" + this.visitedPath, "visited")
                this.$gtm.push({
                    event: "first_page_visit",
                    first_page_visit_data: {
                        customer_id: this.customer.hashid,
                        path: this.visitedPath,
                        env: this.env,
                    },
                })
            }
            if (this.visitedPathIsValid) {
                this.$axios
                    .$post(`${this.customer.url}visit/`, {
                        path: this.visitedPath,
                    })
                    .then(data => {
                        this.customerVisit = data
                    })
            }
        },
        virtualPageView({ url, title }) {
            this.safeRegisterCustomerVisit()
            if (this.$gtm) {
                this.$gtm.push({
                    event: "VirtualPageView",
                    virtualPageUrl: url,
                    virtualPageTitle: title,
                })
            }
        },
    },
    watch: {
        customer(value, prevValue) {
            if (value && value.url) {
                if (!prevValue || !prevValue.url) {
                    this.registerCustomerVisit()
                }
            }
        },
        visitedPathWithoutQuery(value, prevValue) {
            if (this.isMounted && value && prevValue && value !== prevValue) {
                this.registerCustomerVisit()
            }
        },
    },
})

export default CustomerVisitMixin
